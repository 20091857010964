import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import Container from '../components/Containers/Container';
import LoadingSpinner from '../services/LoadingSpinner';
import Toast from '../services/Toast';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:80';

const GPACalculator = () => {
    const [calculationMode, setCalculationMode] = useState('what-if');
    const [file, setFile] = useState(null);
    const [courses, setCourses] = useState([{ code: '', grade: '', credits: '' }]);
    const [cumulativeData, setCumulativeData] = useState({
        currentGPA: '',
        totalCredits: ''
    });
    const [gpa, setGpa] = useState(null);
    const [user, setUser] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isLoadingCourses, setIsLoadingCourses] = useState(false);

    useEffect(() => {
        if (calculationMode === 'full') {
            const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
                if (currentUser) {
                    setUser(currentUser);
                    fetchUserData(currentUser.uid);
                } else {
                    resetCalculator();
                }
            });
            return () => unsubscribe();
        }
    }, [calculationMode]);

    const resetCalculator = () => {
        setUser(null);
        setCourses([{ code: '', grade: '', credits: '' }]);
        setGpa(null);
        setCumulativeData({ currentGPA: '', totalCredits: '' });
    };

    const fetchUserData = async (userId) => {
        setIsLoadingCourses(true);
        try {
            const userDocRef = doc(db, 'Courses', userId);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                
                const fetchedCourses = userData.courses;
                if (fetchedCourses && Array.isArray(fetchedCourses) && fetchedCourses.length > 0) {
                    const validCourses = fetchedCourses.map(course => ({
                        code: course.code || '',
                        grade: course.grade || '',
                        credits: course.credits || ''
                    }));
                    
                    const lastCourse = validCourses[validCourses.length - 1];
                    if (lastCourse.code && lastCourse.grade && lastCourse.credits) {
                        validCourses.push({ code: '', grade: '', credits: '' });
                    }
                    
                    setCourses(validCourses);
                } else {
                    setCourses([{ code: '', grade: '', credits: '' }]);
                }

                calculateGPA(
                    fetchedCourses || [{ code: '', grade: '', credits: '' }],
                    { currentGPA: '', totalCredits: '' }
                );
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setIsLoadingCourses(false);
        }
    };

    const saveUserData = async (userId, coursesToSave) => {
        if (!userId) return;
    
        try {
            const userDocRef = doc(db, 'Courses', userId);
            
            const validCourses = coursesToSave
                .filter(course => course.code && course.grade && course.credits)
                .map(course => ({
                    code: course.code.trim(),
                    credits: course.credits.toString(),
                    grade: course.grade
                }));

            await setDoc(userDocRef, {
                courses: validCourses,
                updatedAt: serverTimestamp()
            }, { merge: true });
        } catch (error) {
            console.error('Error saving data:', error);
            throw error;
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) return;
        setIsUploading(true);
        const formData = new FormData();
        formData.append('transcript', file);
    
        try {
            const response = await axios.post(`${API_URL}/upload`, formData);
            
            const uploadedCourses = response.data.courses.map(course => ({
                code: course.code || '',
                grade: course.grade || '',
                credits: course.credits ? course.credits.toString() : ''
            }));

            const newCourses = [...uploadedCourses];
            const lastCourse = newCourses[newCourses.length - 1];
            if (lastCourse && lastCourse.code && lastCourse.grade && lastCourse.credits) {
                newCourses.push({ code: '', grade: '', credits: '' });
            }

            setCourses(newCourses);
            calculateGPA(newCourses, cumulativeData);
            
            if (user && calculationMode === 'full') {
                await saveUserData(user.uid, newCourses);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleManualCourseChange = (e, index) => {
        const { name, value } = e.target;
        const updatedCourses = [...courses];
        updatedCourses[index] = { ...updatedCourses[index], [name]: value };
        setCourses(updatedCourses);
        calculateGPA(updatedCourses, cumulativeData);
    };

    const handleCumulativeDataChange = (e) => {
        const { name, value } = e.target;
        const updatedCumData = { ...cumulativeData, [name]: value };
        setCumulativeData(updatedCumData);
        calculateGPA(courses, updatedCumData);
    };

    const handleManualCourseAdd = () => {
        setCourses([...courses, { code: '', grade: '', credits: '' }]);
    };

    const handleRemoveCourse = (index) => {
        const updatedCourses = [...courses];
        updatedCourses.splice(index, 1);
        if (updatedCourses.length === 0) {
            updatedCourses.push({ code: '', grade: '', credits: '' });
        }
        setCourses(updatedCourses);
        calculateGPA(updatedCourses, cumulativeData);
    };

    const calculateGPA = (courses, cumData) => {
        const gradePoints = {
            'A+': 4.00, 'A': 3.75, 'B+': 3.50, 'B': 3.00,
            'C+': 2.50, 'C': 2.00, 'D+': 1.50, 'D': 1.00, 'F': 0.00, 'NP': 0.00
        };

        let newCredits = 0;
        let newGradePoints = 0;

        courses.forEach(course => {
            const credits = parseFloat(course.credits);
            const grade = course.grade;
            if (grade !== 'NP' && grade !== '' && !isNaN(credits)) {
                newCredits += credits;
                newGradePoints += credits * gradePoints[grade];
            }
        });

        let finalGPA;
        if (calculationMode === 'what-if') {
            const prevGPA = parseFloat(cumData.currentGPA) || 0;
            const prevCredits = parseFloat(cumData.totalCredits) || 0;
            const totalCredits = prevCredits + newCredits;
            
            finalGPA = totalCredits === 0 ? 0 : 
                ((prevGPA * prevCredits) + newGradePoints) / totalCredits;
        } else {
            finalGPA = newCredits === 0 ? 0 : newGradePoints / newCredits;
        }
            
        setGpa(finalGPA.toFixed(3));
    };

    const handleSave = async () => {
        if (!user || calculationMode !== 'full') return;
        setIsSaving(true);
        
        try {
            await saveUserData(user.uid, courses);
            setShowToast(true);
            setTimeout(() => setShowToast(false), 3000);
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleModeChange = (mode) => {
        setCalculationMode(mode);
        resetCalculator();
    };
    return (
        <Container extraClasses='mx-12'>
            <h1 className="text-2xl md:text-4xl font-bold text-center text-gray-800 mb-2 md:mb-8">
                GPA Calculator
            </h1>

            <div className="flex justify-center mb-4 md:mb-8  w-full">
                <div className="bg-pink-100 p-1 rounded-full text-base md:text-lg w-full flex justify-between">
                    <button
                        onClick={() => handleModeChange('full')}
                        className={`px-3 md:px-6 py-2 rounded-full transition-all duration-300 w-full text-center ${
                            calculationMode === 'full'
                                ? 'bg-pink-500 text-white'
                                : 'text-pink-600'
                        }`}
                    >
                        Full Calculator
                    </button>
                    <button
                        onClick={() => handleModeChange('what-if')}
                        className={`px-3 md:px-6 py-2 rounded-full transition-all duration-300 w-full text-center ${
                            calculationMode === 'what-if'
                                ? 'bg-pink-500 text-white'
                                : 'text-pink-600'
                        }`}
                    >
                        What-if Calculator
                    </button>
                </div>
            </div>

            {calculationMode === 'full' ? (
                // Full Calculator Mode with all features
                !user ? (
                    <div className="text-center text-gray-600">
                        Please log in to use the full calculator features.
                    </div>
                ) : isLoadingCourses ? (
                    <div className="flex justify-center items-center my-4">
                        <LoadingSpinner size="lg" />
                    </div>
                ) : (
                    <>
                        <div className="mb-4 md:mb-8 w-full">
                            <label htmlFor="transcript" className="block text-sm font-medium text-gray-700 mb-2 ml-1">
                                Upload Transcript
                            </label>
                            <div className="flex flex-col justify-start md:flex-row md:justify-center md:items-center items-start space-y-2 md:space-x-4">
                                <input
                                    id="transcript"
                                    type="file"
                                    onChange={handleFileChange}
                                    className="block w-full text-xs sm:text-sm text-gray-500 file:mr-4 file:py-2 file:px-2 sm:file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-pink-50 file:text-pink-700 hover:file:bg-pink-100"
                                />
                                <button
                                    onClick={handleUpload}
                                    disabled={isUploading}
                                    className="bg-pink-500 hover:bg-pink-600 text-white text-sm font-bold py-2 px-4 xs:px-6 sm:px-8 rounded-full transition duration-300 disabled:bg-pink-300 flex items-center space-x-2"
                                >
                                    {isUploading ? (
                                        <>
                                            <LoadingSpinner size="sm" />
                                            <span>Uploading...</span>
                                        </>
                                    ) : (
                                        'Upload'
                                    )}
                                </button>
                            </div>
                        </div>

                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 md:mb-4">Your Courses</h2>
                    </>
                )
            ) : (
                // What-if Calculator Mode - Simple mode without auth/saving
                <>
                    <div className="mb-4 md:mb-8 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2 ml-1">
                                Current Cumulative GPA
                            </label>
                            <input
                                type="number"
                                step="0.001"
                                min="0"
                                max="4"
                                name="currentGPA"
                                value={cumulativeData.currentGPA}
                                onChange={handleCumulativeDataChange}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                                placeholder="Enter your current GPA"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2 ml-1">
                                Total Credits Earned
                            </label>
                            <input
                                type="number"
                                min="0"
                                name="totalCredits"
                                value={cumulativeData.totalCredits}
                                onChange={handleCumulativeDataChange}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                                placeholder="Enter total credits earned"
                            />
                        </div>
                    </div>

                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Add Your Planned Courses</h2>
                </>
            )}
            
            <div className="max-h-52 md:max-h-64 overflow-y-auto space-y-2 w-full">
                {courses.map((course, index) => (
                    <div key={index} className="flex justify-between items-center space-y-0 space-x-1 md:space-x-2 ">
                        <input
                            type="text"
                            name="code"
                            value={course.code}
                            onChange={(e) => handleManualCourseChange(e, index)}
                            placeholder="Course Code"
                            className="flex-1 min-w-28 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                        />
                        <select
                            name="grade"
                            value={course.grade}
                            onChange={(e) => handleManualCourseChange(e, index)}
                            className="flex-1 min-w-12 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                        >
                            <option value="">Select Grade</option>
                            {['A+', 'A', 'B+', 'B', 'C+', 'C', 'D+', 'D', 'F', 'NP'].map(grade => (
                                <option key={grade} value={grade}>{grade}</option>
                            ))}
                        </select>
                        <input
                            type="number"
                            name="credits"
                            value={course.credits}
                            onChange={(e) => handleManualCourseChange(e, index)}
                            placeholder="Credits"
                            className="flex-1 min-w-12 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                        />
                        {courses.length > 1 && (
                            <button
                                onClick={() => handleRemoveCourse(index)}
                                className="text-pink-700 hover:text-pink-800 transition duration-300 rounded-full text-xl  pl-1"
                            >
                                ✖
                            </button>
                        )}
                    </div>
                ))}
            </div>
            
            <button
                onClick={handleManualCourseAdd}
                className="mt-6 bg-pink-100 hover:bg-pink-200 text-pink-500 font-bold py-2 px-4 rounded-full transition duration-300 mx-auto block"
            >
                + Add Course
            </button>

            <div className="mt-8 text-center">
                <h2 className="text-xl md:text-3xl font-bold text-gray-800">
                    {calculationMode === 'what-if' ? 'Predicted ' : ''}
                    Cumulative GPA: <span className="text-pink-600">{gpa || '0.000'}</span>
                </h2>
                {calculationMode === 'what-if' && cumulativeData.currentGPA && cumulativeData.totalCredits && (
                    <p className="mt-2 text-gray-600">
                        Based on current GPA of {cumulativeData.currentGPA} with {cumulativeData.totalCredits} credits
                    </p>
                )}
            </div>

            {calculationMode === 'full' && user && (
                <>
                    <button
                        onClick={handleSave}
                        disabled={isSaving}
                        className="mt-6 bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 mx-auto block disabled:bg-pink-300 flex items-center justify-center space-x-2"
                    >
                        {isSaving ? (
                            <>
                                <LoadingSpinner size="sm" />
                                <span>Saving...</span>
                            </>
                        ) : (
                            'Save'
                        )}
                    </button>

                    {showToast && (
                        <Toast 
                            message="Changes saved successfully!" 
                            type="success"
                            onClose={() => setShowToast(false)}
                        />
                    )}
                </>
            )}
        </Container>
    );
};

export default GPACalculator;